<template>
  <div class="body-cont">
    <MagnifyingGlass />
    <hr />
    <div class="main">
      <h1>Exam not available</h1>
      <p class="message">{{ message }}</p>
      <button @click="logout" class="btnSignout">Sign Out</button>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import MagnifyingGlass from "@/components/loaders/MagnifyingGlass.vue";
export default {
  name: "ExamNotAvailable",
  data() {
    return {
      message: "",
      examNotStarted: false,
      examEnded: false,
      examNotFound: false,
      notEligible: false,
      startDate: "",
      endDate: "",
      examTaken: false,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  components: {
    MagnifyingGlass,
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    getDate(date) {
      const month = this.months[date.getMonth()];
      const year = date.getFullYear();
      const day = date.getDate();

      return `${day} ${month} ${year}`;
    },

    logout() {
      const redirect = localStorage.getItem('redirect');
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("examAnswers");
      localStorage.removeItem("examQuestions");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("redirect");
      if (redirect) {
        window.location.href = redirect;
        return;
      }
      this.$router.go(-1);
    },
  },
  mounted() {
    const route = useRoute();
    console.log(route.params);
    if (!this.isEmpty(route.params)) {
      this.examNotStarted =
        route.params.examNotStarted == "false" ? false : true;
      this.examEnded = route.params.examEnded == "false" ? false : true;
      this.examNotFound = route.params.examNotFound == "false" ? false : true;
      this.notEligible = route.params.notEligible == "false" ? false : true;
      // this.examTaken = route.params.examTaken == "false" ? false : true;
      this.startDate = this.getDate(new Date(route.params.startDate));
      this.endDate = this.getDate(new Date(route.params.endDate));

      console.log(this.examNotFound);

      // if (this.examTaken)
      //         this.message =
      //   "You have already written this exam, please reach out to your assessor for any appeals.";
      if (this.examNotFound)
        this.message =
          "Sorry the exam you are looking for was not found! Please find another exam.";
      else if (this.notEligible)
        this.message =
          "Sorry you are not eligible to take this exam, please try another one.";
      else if (this.examNotStarted)
        this.message = `This exam has not yet started, it will start on ${this.startDate}`;
      else if (this.examEnded)
        this.message = `This exam ended on ${this.endDate}`;
    }
  },
};
</script>
<style scoped>
.main {
  display: block;
  margin-top: 12vh;
  text-align: center;
  text-align: -webkit-center;
}

.body-cont {
  display: block;
}

.btnSignout {
  margin-top: 4rem;
  border: none;
  border-radius: 1.5rem;
  background: #00b871;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: 3rem;
  cursor: pointer;
}

hr {
  background: #00b871;
}

.message {
  width: 50%;
}
</style>