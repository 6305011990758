<template>
        <div class="loader-container">
        <span></span>
        <div class="center">
            <div class="wrap">
                <div class="box-1 box">
                  <div class="svg"></div>
                    <div class="svg"></div>
                </div>
                <div class="box-2 box">
                    <div class="svg"></div>
                    <div class="svg"></div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: 'MagnifyingGlass'
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}

body {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgb(255, 255, 255);
}

.loader-container .box div.svg {
background: url("data:image/svg+xml,%3Csvg width='22' height='31' viewBox='0 0 22 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.494136 30.3273H10.8711C11.144 30.3273 11.3653 30.1083 11.3653 29.8382C11.3653 29.568 11.144 29.3491 10.8711 29.3491H0.988279V0.978305H14.8242V5.38067C14.8242 5.65079 15.0455 5.86981 15.3184 5.86981H19.7657V20.5443C19.7657 20.8144 19.9869 21.0334 20.2598 21.0334C20.5327 21.0334 20.754 20.8144 20.754 20.5443V5.40501C20.7528 5.39187 20.7508 5.37896 20.7484 5.36612C20.7467 5.24191 20.6968 5.12346 20.6091 5.03479L15.6735 0.143305C15.6598 0.133039 15.6453 0.123721 15.6306 0.114885C15.6005 0.0890866 15.5674 0.0668734 15.5317 0.0489612C15.5136 0.0413234 15.4952 0.0348676 15.4765 0.0293765C15.4364 0.0159987 15.3949 0.00787893 15.3529 0.00549115C15.3396 0.00549115 15.3292 0 15.3184 0H0.494136C0.221249 0 0 0.219022 0 0.489152V29.8382C0 30.1083 0.221249 30.3273 0.494136 30.3273ZM15.8125 1.67046L19.0471 4.89152H15.8125V1.67046Z' fill='%2300B871'/%3E%3Cpath d='M14.912 22.9721H7.50114L6.31314 26.4441H1.25L8.43457 6.78809H14.0351L21.2197 26.4441H16.1L14.912 22.9721ZM13.6674 19.2761L11.2066 12.0801L8.774 19.2761H13.6674Z' fill='%232F2E41'/%3E%3C/svg%3E%0A")
  no-repeat
    left center;
  background-size: 50px 60px;
  margin: 16px;
  height: 60px;
  width: 50px;
}

.loader-container {
    position: relative;
    height: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
                margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.loader-container .center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    background-color: #fff;
    border: 9px solid #DEE1E4;
    overflow: hidden;
}

.loader-container::before {
    content: "";
    position: absolute;
    top: 210px;
    left: 26px;
    width: 100px;
    height: 32px;
    border-radius: 100%;
    background-color: rgba(222, 225, 228, 0.4);
}

.loader-container .wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.loader-container span::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 6px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.loader-container span::after {
    content: "";
    position: absolute;
    left: 30px;
    top: 20px;
    width: 36px;
    height: 12px;
    background-color: #DEE1E4;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-radius: 0 4px 4px 0;
}

.loader-container .box-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.loader-container .box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

/* animation */

@-webkit-keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@-webkit-keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@keyframes rowup-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
    }
}

@-webkit-keyframes cloud-loop {
    0% {
        -webkit-transform: translate(0, 15px);
        transform: translate(0, 15px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@-webkit-keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }
    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

@keyframes shadow-loop {
    0% {
        -webkit-transform: translate(0, -35px) scale(1.15, 0.25);
        transform: translate(0, -35px) scale(1.15, 0.25);
    }
    100% {
        -webkit-transform: translate(0, -35px) scale(1, 0.25);
        transform: translate(0, -35px) scale(1, 0.25);
    }
}

.loader-container .box-1 {
    -webkit-animation: 1s rowup-1 linear infinite normal;
            animation: 1s rowup-1 linear infinite normal;
}

.loader-container .box-2 {
    -webkit-animation: 1s rowup-2 linear infinite normal;
            animation: 1s rowup-2 linear infinite normal;
}

.loader-container .center {
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.loader-container span {
    position: relative;
    left: 112px;
    top: 60px;
    -webkit-animation-name: cloud-loop;
    animation-name: cloud-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.loader-container::before {
    -webkit-animation-name: shadow-loop;
    animation-name: shadow-loop;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}
</style>
