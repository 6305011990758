<template>
  <div class="col-md-9 register-right">
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <!-- <h3 class="register-heading">Sign In</h3> -->
        <img class="logo" src="@/assets/AssessrLogo.svg" />
        <p class="moto">
          Sign In with <span class="assessr-highlight">AssessR</span> to attempt
          Exam
        </p>
        <form @submit.prevent>
          <div class="column register-form">
            <div class="col-md-6">
              <div class="form-group">
                <label for="user-identify">Student Email</label>
                <input id="user-identify" type="email" class="form-control input-field" v-model="email" required />
              </div>
            </div>
            <div v-if="errors.emptyEmail" class="error input-fields">
              <Error :errorMessage="errorMessages.emptyUsernameMessage" />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="user-password">Password</label>
                <input id="user-password" type="password" class="form-control input-field" v-model="password"
                  required />
              </div>
              <div v-if="errors.emptyPassword" class="error input-fields">
                <Error :errorMessage="errorMessages.emptyPasswordMessage" />
              </div>
              <div v-if="errors.loginFailed" class="error">
                <Error :errorMessage="errorMessages.loginFailedMessage" />
              </div>
              <!-- Deal with submit accordingly -->
              <button v-if="!loading" @click="postLogin" class="btnLogin">
                Login
              </button>
              <div v-else class="load">
                <DottedRoundBorder />
              </div>
              <!-- <div class="no-account">
                <router-link :to="{ path: '/signup', query: $route.query }">Don't have an account?</router-link>
              </div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DottedRoundBorder from "@/components/loaders/DottedRoundLoader";
import Error from "@/components/validation/Error";
import { useRoute } from "vue-router";
import { login } from "@/services/user.service";
import jwt from 'jsonwebtoken';

export default {
  name: "Login",
  components: {
    DottedRoundBorder,
    Error
  },
  data() {
    return {
      errorMessages: {
        loginFailedMessage: "Incorrect email or password!",
        emptyUsernameMessage: "Username can not be empty",
        emptyPasswordMessage: "Password can not be empty",
      },
      errors: {
        loginFailed: false,
        emptyEmail: false,
        emptyPassword: false,
      },
      loading: false,
      email: "",
      password: "",
      gradeId: 0,
      subjectId: 0,
      classId: 0,
      examId: 0,
      bypass: 0,
      organisationId: 0,
      emptyQuery: false,
      examAvailable: true,
      studentId: 0,
    };
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async postLogin() {
      // const sess = this.$session;
      const inputFields = document.getElementsByClassName("form-control");
      const inputFieldsList = [];
      inputFieldsList.push(inputFields[0]);
      inputFieldsList.push(inputFields[1]);

      this.errors.emptyEmail = false;
      this.errors.emptyPassword = false;

      inputFieldsList.forEach((inputField) => {
        inputField.classList.remove("has-error");
      });
      if (this.email.length != 0 && this.password.length != 0) {
        this.loading = true;
        this.errors.loginFailed = false;
        const response = await login(
          {
            email: this.email,
            password: this.password,
            examId: this.examId,
          }
        );
        const resPayload = response.data;
        if (response.status === 200 && resPayload != null) {
          if (!resPayload.incorrectLogin) {
            localStorage.setItem("loggedInUser", JSON.stringify(resPayload));
            localStorage.setItem("accessToken", resPayload.token);
            this.studentId = resPayload.id;
            await this.checkExamAvailability();
            if (this.examAvailable) {
              this.$router.push({ name: "HomePage" });
            }
            this.loading = false;
          } else {
            this.loading = false;
            this.errors.loginFailed = true;
            inputFieldsList.forEach((inputField) => {
              inputField.classList.add("has-error");
            });
          }
        }
      } else {
        if (this.email.length == 0) {
          this.errors.emptyEmail = true;
          inputFields[0].classList.add("has-error");
        }
        if (this.password.length == 0) {
          this.errors.emptyPassword = true;
          inputFields[1].classList.add("has-error");
        }
      }
    },

    async updateUserSurveyStatus() {
      try {
        await axios.put(
          `${window.apiUrl}/learners/update-survey-status/${this.studentId}`,
          {
            status: true
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
        );
      }
      catch (error) {
        console.error(error);
      }
    },

    async checkExamAvailability() {
      try {
        const response = await axios.get(
          `${window.apiUrl}/exams/exam_availability/${this.examId}/${this.studentId}/${this.classId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
        );
        if (response.status === 200) {
          const result = response.data;
          if (
            result.examNotStarted ||
            result.examNotFound ||
            result.examEnded ||
            result.notEligible
          ) {
            console.log("not eligible");
            this.examAvailable = false;
            this.$router.push({
              name: "ExamNotAvailable",
              params: {
                ...result,
              },
            });
          } else {
            await this.prepareQuestions();
            this.examAvailable = true;
          }

          // for fast unchecked login
          // await this.prepareQuestions();
          // this.examAvailable = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async prepareQuestions() {
      let questions = [];
      let response;
      let examAttemptResponse;
      try {
        response = await axios.get(`${window.apiUrl}/exams/${this.examId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
      } catch (error) {
        console.error('Error fetching exam data:', error);
        throw error;
      }

      try {
        examAttemptResponse = await axios.get(`${window.apiUrl}/exams/script_body/examwithstudent/${this.examId}/${this.studentId}`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } });
      } catch (error) {
        console.error('Error fetching exam attempt data:', error);
        examAttemptResponse = { data: [] }; // default to empty array if error occurs
      }

      if (response.status === 200) {
        const result = response.data;
        let questionIds = [];
        let topicIds = [];
        let questionAnswers = [];
        result.map((elem) => {
          if (!questionIds.includes(elem.id)) questionIds.push(elem.id);
          if (!topicIds.includes(elem.topic_id)) topicIds.push(elem.topic_id);
        });

        questionIds.forEach((Id) => {
          const qCard = {
            questionId: Id,
            questionType: result.find((x) => x.id === Id).question_type,
            questionsBody: result.filter((x) => x.id === Id),
          };

          let foundCard;
          if (examAttemptResponse.data.length > 0) {
            for (let i = 0; i < examAttemptResponse.data.flatMap(record => record.mark_script_body.record).length; i++) {
              foundCard = examAttemptResponse.data.flatMap(record => record.mark_script_body.record)[i].questionCards.find((card) => card.id === Id);
              if (foundCard) break;
            }
          }

          const qAnswer = {
            questionId: Id,
            answer: qCard.questionsBody[0].answer,
            questionFeedback: foundCard ? foundCard.questionFeedback || "" : "",
            questionUserAnswer: foundCard ? foundCard.userAnswer || "" : "",
            questionChecked: foundCard ? foundCard.qChecked || false : false,
            questionUserMark: foundCard ? parseInt(foundCard.questionUserMark) : -1,
            questionTotalMark: foundCard ? parseInt(foundCard.questionTotalMark) : -1,
            fillBlankQuestions: foundCard ? foundCard.questions : [],
          };
          questions.push(qCard);
          questionAnswers.push(qAnswer);
        });

        localStorage.setItem("examQuestions", JSON.stringify(questions));
        localStorage.setItem("examAnswers", JSON.stringify(questionAnswers));
      }
    },
  },

  beforeCreate() {
    // if (!localStorage.getItem('tokenUsed')) {
    if (window.location.href.includes('token')) {
      let url = new URL(window.location.href);
      let tokenObject = jwt.decode(url.searchParams.get('token'));
      localStorage.setItem('loggedInUser', JSON.stringify(tokenObject))
      localStorage.setItem('accessToken', url.searchParams.get('token'))
      localStorage.setItem('redirect', url.searchParams.get('redirectUrl'))
      url.searchParams.delete('token');
      url.searchParams.delete('redirectUrl');
      window.history.pushState({}, document.title, url.toString());
    }
    // } 
    // else {
    //   let url = new URL(window.location.href);
    //   url.searchParams.delete('token');
    //   window.history.pushState({}, document.title, url.toString());
    //   localStorage.removeItem('tokenUsed');
    // }
  },

  mounted() {
    const route = useRoute();

    if (!this.isEmpty(route.query)) {
      this.gradeId = route.query.gradeId;
      this.subjectId = route.query.subjectId;
      this.classId = route.query.classId;
      this.examId = route.query.examId;
      this.organisationId = route.query.organisationId;
      this.emptyQuery = false;
    } else {
      this.emptyQuery = true;
    }

    let loggedIn = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedIn != null) {
      this.studentId = loggedIn.id;
      let url = new URL(window.location.href);
      if (loggedIn.isOAuth && url.searchParams.get('survey_done')) {
        this.updateUserSurveyStatus();
      }
      this.checkExamAvailability().then(() => {
        if (this.examAvailable) {
          this.$router.push({ name: "HomePage" });
          this.$destroy;
        }
        return;
      });
    }
  },
};
</script>
<style scoped>
label {
  float: left;
  font-weight: 500;
}

.no-account a:link {
  color: #495057;
  background-color: transparent;
  text-decoration: none;
}

.no-account a:visited {
  color: #00b871;
  background-color: transparent;
  text-decoration: none;
}

.logo {
  width: 30%;
}

.assessr-highlight {
  color: #00b871;
  font-weight: 500;
}

.input-field {
  padding: 25px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
}

input::placeholder {
  color: #b1abab;
}

.error.input-fields {
  margin-top: -1rem;
}

.has-error {
  border: 1px solid red;
  /* border-radius: 4px; */
}

.load {
  margin-top: 2rem;
}

.register-right {
  /* background: #f8f9fa; */
  /* border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%; */
}

.register-right .register-form {
  margin-top: 9%;
  text-align: center;
  text-align: -webkit-center;
}

.btnLogin {
  float: right;
  margin-bottom: 4rem;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #00b871;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: 3rem;
  cursor: pointer;
}

.register-right .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #00b871;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}

.register-right .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register-right .nav-tabs .nav-link:hover {
  border: none;
}

.register-right .nav-tabs .nav-link.active {
  width: 100px;
  color: #00b871;
  border: 2px solid #00b871;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
</style>