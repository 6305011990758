<template>
  <div class="main-container">
    <div class="container register">
      <div class="row">
        <!-- <RegisterLeft/> -->
        <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                   <component :is="Component" />
                 </transition> 
          </router-view>

      </div>
    </div>
  </div>
</template>
<script>
// import RegisterLeft from "@/components/signuplogin/RegisterLeft";

import { defineComponent } from "@vue/runtime-core"

export default defineComponent ({
  name: "SignupLogin",
  components: {
        // RegisterLeft
    }
});
</script>
<style scoped>
.main-container {
  background: #fff;
  padding: 3%;
  height: 100vh;
}

.register {
  /* background: -webkit-linear-gradient(left, #3931af, #00c6ff); */
  /* margin-top: 3%;
  padding: 3%; */
}

.register > div:nth-child(1){
  justify-content: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>