<template>
  <div class="col-md-9 register-right">
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <!-- <h3 class="register-heading">Sign In</h3> -->
        <img class="logo" src="@/assets/AssessrLogo.svg" />

        <form @submit.prevent>
          <div class="column register-form">
            <div class="col-md-6">
              <div class="form-group">
                <label for="user-name">Name</label>
                <input
                  id="user-name"
                  type="text"
                  class="form-control input-field"
                  v-model="username"
                  required
                />
              </div>
            </div>
            <div v-if="errors.emptyUsername" class="error input-fields">
              <Error :errorMessage="errorMessages.emptyUsernameMessage" />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="surname">Surname</label>
                <input
                  id="surname"
                  type="text"
                  class="form-control input-field"
                  v-model="surname"
                  required
                />
              </div>
            </div>
            <div v-if="errors.emptySurname" class="error input-fields">
              <Error :errorMessage="errorMessages.emptySurnameMessage" />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  id="email"
                  type="text"
                  class="form-control input-field"
                  v-model="email"
                  required
                />
              </div>
            </div>
            <div v-if="errors.emptyEmail" class="error input-fields">
              <Error :errorMessage="errorMessages.emptyEmailMessage" />
            </div>
            <div class="col-md-6" v-if="!usedExamLink">
              <div class="form-group">
                <label for="email">Grade</label>
                <select
                  id="grade-select"
                  class="form-control"
                  @change="setGrade($event)"
                >
                  <option
                    v-for="(grade, index) in grades"
                    :key="grade.id"
                    class="hidden"
                    :selected="index == 0 ? true : false"
                    :disabled="index == 0 ? true : false"
                    :value="grade.id"
                    :id="'grade-' + grade.id"
                  >
                    {{ grade.grade }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6" v-if="!usedExamLink">
              <div class="form-group">
                <label for="email">School / Organisation</label>
                <select
                  id="organisation-select"
                  class="form-control"
                  @change="setOrganisation($event)"
                >
                  <option
                    v-for="(organisation, index) in organisations"
                    :key="organisation.id"
                    class="hidden"
                    :selected="index == 0 ? true : false"
                    :disabled="index == 0 ? true : false"
                    :value="organisation.id"
                    :id="'org-' + organisation.id"
                  >
                    {{ organisation.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="user-password">Password</label>
                <input
                  id="user-password"
                  type="password"
                  class="form-control input-field"
                  v-model="password"
                  required
                />
              </div>
              <div v-if="errors.emptyPassword" class="error input-fields">
                <Error :errorMessage="errorMessages.emptyPasswordMessage" />
              </div>
              <div v-if="errors.signupFailed" class="error">
                <Error :errorMessage="errorMessages.loginFailedMessage" />
              </div>
              <!-- Deal with submit accordingly -->
              <button v-if="!loading" @click="postSignUp" class="btnLogin">
                Sign Up
              </button>
              <div v-else class="load">
                <DottedRoundBorder />
              </div>
              <div class="has-account">
                <router-link
                  id="route-login"
                  :to="{ path: '/login', query: $route.query }"
                  >Back to login?</router-link
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DottedRoundBorder from "@/components/loaders/DottedRoundLoader";
import Error from "@/components/validation/Error";
import { useRoute } from "vue-router";

// const apiUrl = "http://localhost:3001/api";
export default {
  name: "SignUp",
  components: {
    DottedRoundBorder,
    Error,
  },
  data() {
    return {
      errorMessages: {
        loginFailedMessage: "Incorrect username or password!",
        emptyUsernameMessage: "Username can not be empty",
        emptySurnameMessage: "Surname can not be empty",
        emptyEmailMessage: "Surname can not be empty",
        emptyPasswordMessage: "Password should contain 5 characters or more",
      },
      errors: {
        emptyUsername: false,
        emptySurname: false,
        emptyEmail: false,
        emptyPassword: false,
      },
      loading: false,
      username: "",
      password: "",
      surname: "",
      email: "",
      gradeId: 0,
      subjectId: 0,
      classId: 0,
      examId: 0,
      organisationId: 0,
      emptyQuery: false,
      organisations: [],
      grades: [],
      usedExamLink: true,
    };
  },
  methods: {
    setOrganisation(event) {
      this.organisationId = event.target.value;
    },

    setGrade(event) {
      this.gradeId = event.target.value;
    },

    addOrganisationsOptions(responseData) {
      // <option class="hidden" selected disabled>Select Grade</option>

      this.organisations.push({
        id: 0,
        name: "Select Organisation",
      });
      responseData.forEach((item) => {
        this.organisations.push({
          id: item.id,
          name: item.name,
        });
      });
    },

    addGradesOptions(responseData) {
      // <option class="hidden" selected disabled>Select Grade</option>

      this.grades.push({
        id: 0,
        grade: "Select Grade",
      });
      responseData.forEach((item) => {
        this.grades.push({
          id: item.id,
          grade: item.grade,
        });
      });
    },

    async getGrades() {
      axios
        .get(`${window.apiUrl}/learners/grades`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } })
        .then((response) => {
          this.addGradesOptions(response.data);
        })
        .catch((error) => console.error(error));
    },

    async getOrganisations() {
      axios
        .get(`${window.apiUrl}/organisation`,
          { headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } })
        .then((response) => {
          this.addOrganisationsOptions(response.data);
        })
        .catch((error) => console.error(error));
    },

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async postSignUp() {
      let inputFields = document.getElementsByClassName("form-control");
      const fieldsList = [];
      fieldsList.push(inputFields[0]);
      fieldsList.push(inputFields[1]);
      fieldsList.push(inputFields[2]);
      fieldsList.push(inputFields[3]);

      if (!this.usedExamLink) fieldsList.push(inputFields[4]);

      this.errors.emptyUsername = false;
      this.errors.emptyPassword = false;
      this.errors.emptySurname = false;
      this.errors.emptyEmail = false;

      fieldsList.forEach((inputField) => {
        inputField.classList.remove("has-error");
      });
      if (this.username.length != 0 && this.password.length != 0) {
        this.loading = true;
        const response = await axios.post(`${window.apiUrl}/learners/signup`, {
          username: this.username,
          password: this.password,
          surname: this.surname,
          email: this.email,
          gradeId: this.gradeId,
          classId: this.classId,
          organisationId: this.organisationId,
          examId: this.examId,
          usedExamLink: this.usedExamLink,
        });
        if (response.status == 200 && response.data != null) {
          if (response.data.userRegistered) {
            this.loading = false;
            const btn = document.getElementById("route-login");

            btn.click();
          } else {
            this.loading = false;
            this.errors.signupFailed = true;
            fieldsList.forEach((inputField) => {
              inputField.classList.add("has-error");
            });
          }
        }
      } else {
        if (this.username.length == 0) {
          this.errors.emptyUsername = true;
          inputFields[0].classList.add("has-error");
        }
        if (this.password.length == 0) {
          this.errors.emptyPassword = true;
          inputFields[3].classList.add("has-error");
        }
        if (this.surname.length == 0) {
          this.errors.emptySurname = true;
          inputFields[1].classList.add("has-error");
        }
        if (this.email.length == 0) {
          this.errors.emptyEmail = true;
          inputFields[2].classList.add("has-error");
        }
      }
    },
  },
  mounted() {
    const route = useRoute();

    if (!this.isEmpty(route.query)) {
      this.gradeId = route.query.gradeId;
      this.subjectId = route.query.subjectId;
      this.classId = route.query.classId;
      this.examId = route.query.examId;
      this.organisationId = route.query.organisationId;
      this.emptyQuery = false;

      if (
        this.organisationId <= 0 ||
        this.gradeId <= 0 ||
        this.gradeId > 5 ||
        this.classId <= 0
      ) {
        this.usedExamLink = false;
        (async () => {
          await this.getOrganisations();
          await this.getGrades();
        })();
      }
    } else {
      this.emptyQuery = true;
      this.usedExamLink = false;
      (async () => {
        await this.getOrganisations();
        await this.getGrades();
      })();
    }

    let loggedIn = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedIn != null) {
      this.$router.push({ name: "Login" });
      this.$destroy;
      return;
    }
  },
};
</script>
<style scoped>
label {
  float: left;
  font-weight: 500;
}

.has-account a:link {
  color: #495057;
  background-color: transparent;
  text-decoration: none;
}

.has-account a:visited {
  color: #00b871;
  background-color: transparent;
  text-decoration: none;
}

.logo {
  width: 30%;
}

.assessr-highlight {
  color: #00b871;
  font-weight: 500;
}

.input-field {
  padding: 25px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 5px;
}

input::placeholder {
  color: #b1abab;
}

.error.input-fields {
  margin-top: -1rem;
}

.has-error {
  border: 1px solid red;
  /* border-radius: 4px; */
}

.load {
  margin-top: 2rem;
}
.register-right {
  /* background: #f8f9fa; */
  /* border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%; */
}

.register-right .register-form {
  margin-top: 9%;
  text-align: center;
  text-align: -webkit-center;
}

.btnLogin {
  float: right;
  margin-bottom: 4rem;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #00b871;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: 3rem;
  cursor: pointer;
}
.register-right .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #00b871;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register-right .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register-right .nav-tabs .nav-link:hover {
  border: none;
}
.register-right .nav-tabs .nav-link.active {
  width: 100px;
  color: #00b871;
  border: 2px solid #00b871;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
</style>