import ILoginModel from "@/shared/models/login-body.model";
import axios from "axios";
const apiUrl = (window as any).apiUrl;

const login = async (loginPayload: ILoginModel) => {
    try {
        const res = await axios.post(`${apiUrl}/learners/login`, loginPayload);
        return res;
    } catch (error) {
        //Developer note: Implement error handler
        return error;
    }
}

export { 
    login
}