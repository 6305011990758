<template>
    <div class="dottedroundloader">

    </div>
</template>

<script>
export default {
    name: "DottedRoundBorder"
}
</script>

<style scoped>
    .dottedroundloader {
	border: .4em dotted #00B871;
	border-radius: 50%;
    width: 25px;
    height: 25px;
	animation: 1s dottedroundloader linear infinite;
}

@keyframes dottedroundloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>