<template>
    <div class="containr">
        <p class="error-txt">
            {{ errorMessage }}
        </p>
    </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    errorMessage: String
  }
}
</script>

<style scoped>
    .containr > p.error-txt {
        font-size: small;
        font-weight: 100;
        color: #ff0d01;
    }
</style>